import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Toolbar from './components/Toolbar';
import Hero from './components/Hero';
import Features from './components/Features';
import Demo from './components/Demo';
import Roadmap from './components/Roadmap';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import AppcastPage from './components/AppcastPage';
import BetaAvailable from './components/BetaAvailable';
import PricingSection from './components/PricingSection';
import PricingRedirect from './components/PricingRedirect';
import RoadmapRedirect from './components/RoadmapRedirect';
import './App.css';

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const savedTheme = localStorage.getItem('isDarkMode');
    
    if (savedTheme !== null) {
      setIsDarkMode(savedTheme === 'true');
    } else {
      setIsDarkMode(prefersDarkMode);
    }
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
    localStorage.setItem('isDarkMode', isDarkMode);
  }, [isDarkMode]);

  const toggleTheme = () => {
    setIsDarkMode(prevMode => !prevMode);
  };

  return (
    <Router>
      <div className="App">
        <Toolbar isDarkMode={isDarkMode} toggleTheme={toggleTheme} />
        <Routes>
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/appcast" element={<AppcastPage />} />
          <Route path="/beta-available.json" element={<BetaAvailable />} />
          <Route path="/pricing" element={<PricingRedirect />} />
          <Route path="/roadmap" element={<RoadmapRedirect />} />
          <Route path="/" element={
            <>
              <Hero />
              <Demo />
              <PricingSection />
              <Roadmap />
            </>
          } />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;