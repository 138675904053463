import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function PricingRedirect() {
  const navigate = useNavigate();
  
  useEffect(() => {
    // First navigate to home page
    navigate('/');
    
    // After navigation completes, scroll to the pricing section
    const scrollToPricing = () => {
      const pricingSection = document.getElementById('pricing');
      if (pricingSection) {
        pricingSection.scrollIntoView({ behavior: 'smooth' });
      }
    };
    
    // Small delay to ensure the DOM has loaded
    setTimeout(scrollToPricing, 100);
  }, [navigate]);

  return (
    <div className="redirect-container">
      <p>Redirecting to pricing information...</p>
    </div>
  );
}

export default PricingRedirect;