import React from 'react';
import ReactPlayer from 'react-player';
import './Demo.css';

function Demo() {
  return (
    <section id="demo" className="demo">
      <div className="demo-content">
        <h2 className="demo-title">See Repo Prompt in Action</h2>
        <p className="demo-subtitle">Watch how easy it is to work with AI and your codebase</p>
        <div className="player-wrapper">
          <ReactPlayer
            url="https://youtu.be/fm3VreCt-5E?si=W088J7lKF_snoV8_"
            playing={true}
            loop={true}
            muted={true}
            playsinline={true}
            controls={true}
            width="100%"
            height="100%"
            className="react-player"
          />
        </div>
      </div>
    </section>
  );
}

export default Demo;