import React from 'react';
import { FaCheck } from 'react-icons/fa';
import './PricingSection.css';

function PricingSection() {
  return (
    <section id="pricing" className="pricing-section">
      <div className="pricing-content">
        <h2 className="pricing-headline">Repo Prompt Pricing</h2>
        
        <div className="pricing-cards">
          <div className="pricing-card free">
            <div className="card-header">
              <div className="tier-name">✨ Free Tier</div>
              <div className="price">$0</div>
              <div className="price-subtitle">Powerful features, zero cost</div>
            </div>
            
            <div className="free-tier-content">
              
              
              <div className="features-intro">Key features include:</div>
              
              <ul className="feature-list">
                <li>
                  <FaCheck className="check-icon" />
                  <div><strong>Mac-Native UX:</strong> Effortlessly build prompts from your codebase with a seamless interface.</div>
                </li>
                <li>
                  <FaCheck className="check-icon" />
                  <div><strong>Clipboard & Chat:</strong> Copy prompts or edit files via API chat (up to 32k tokens) for smooth workflow integration.</div>
                </li>
                <li>
                  <FaCheck className="check-icon" />
                  <div><strong>Workspaces:</strong> Open multiple folders or repos simultaneously (e.g., code + packages or docs) for comprehensive context.</div>
                </li>
                <li>
                  <FaCheck className="check-icon" />
                  <div><strong>Advanced Search & Filtering:</strong> Quickly locate files using regex and git-ignore filters for precise control.</div>
                </li>
                <li>
                  <FaCheck className="check-icon" />
                  <div><strong>Stored Prompts:</strong> Save and organize prompts for efficient reuse across your projects.</div>
                </li>
               
                <li>
                  <FaCheck className="check-icon" />
                  <div><strong>Apply Mode:</strong> Use ChatGPT or Claude outputs to instantly apply multi-file edits (create, modify, delete files; rename coming soon).</div>
                </li>
                <li>
                  <FaCheck className="check-icon" />
                  <div><strong>Model Access:</strong> Connect to local and hosted models (OpenAI, Anthropic, Gemini, DeepSeek, Azure, OpenRouter) - bring your own API key for maximum flexibility.</div>
                </li>
              </ul>
            </div>
            
            <div className="purchase-options">
              <a href="https://d2ruaev2blr0t3.cloudfront.net/RepoPrompt.dmg" className="card-cta free-cta" target="_blank" rel="noopener noreferrer">
                Download Now
              </a>
            </div>
          </div>

          <div className="pricing-card pro">
            
            <div className="card-header">
              <div className="tier-name">🚀 Pro</div>
              
              <div className="pricing-plans">
                <div className="pricing-option-header">Choose your plan:</div>
                <div className="pricing-options">
                  <div className="pricing-option">
                    <div className="option-name">Monthly subscription</div>
                    <div className="price">$14.99<span className="price-period">/month</span></div>
                    <div className="price-details">
                      <div className="update-info">Includes <strong>all updates</strong> during subscription</div>
                      <div className="update-info">Cancel anytime</div>
                    </div>
                  </div>
                  
                  <div className="pricing-option buy-to-own-option">
                      <div className="early-bird">Early Bird Offer</div>
                      <div className="option-name">Buy-to-own</div>
                      <div className="price">$199</div>
                      <div className="lifetime-text">Lifetime access</div>
                      <div className="price-details">
                        <span className="regular-price">$249 regular price</span>
                        <div className="update-info">Includes <strong>1 year of free updates</strong></div>
                        <div className="update-renewal">Additional year: <strong>$99/year</strong> (optional, buy anytime)</div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="features-intro">Everything in Free, plus:</div>
            
            <ul className="feature-list">
              <li>
                <FaCheck className="check-icon" />
                <strong>No token limits for copy and chat</strong>
              </li>
              <li>
                <FaCheck className="check-icon" />
                <strong>CodeMaps: Locally scans entire codebase, extracting classes, functions, and references</strong>
              </li>
              <li>
                <FaCheck className="check-icon" />
                <strong>Pro API Delegation: Efficiently delegate tasks to cost-effective AI models</strong>
              </li>
              <li>
                <FaCheck className="check-icon" />
                <strong>Custom OpenAI-compatible providers</strong>
              </li>
              <li>
                <FaCheck className="check-icon" />
                <div>
                  <strong>Advanced model customization</strong>
                </div>
              </li>
              <li>
                <FaCheck className="check-icon" />
                <strong>Use on up to 3 machines per license</strong>
              </li>
            </ul>
            
            <div className="purchase-options">
              <a href="https://buy.polar.sh/polar_cl_Uh1cZir5adBqHHlAjCBup9xyxduhxY1c7w6fd4QBCrt" className="card-cta pro-cta buy-cta" target="_blank" rel="noopener noreferrer">
                Buy License
              </a>
              <a href="https://buy.polar.sh/polar_cl_7YofmThChXrmc8S3nPfce9Bor3zfAFwtW8zoE1oDVet" className="card-cta pro-cta subscribe-cta" target="_blank" rel="noopener noreferrer">
                Subscribe Monthly
              </a>
            </div>
          </div>
        </div>

        <div className="special-offers">
          <h3 className="special-offers-title">Special Pricing Options</h3>
          
          <div className="special-offer student-offer">
            <h3>🎓 Student Discount</h3>
            <p>Are you a student? We offer special academic pricing! Email us from your .edu address to request a discount code.</p>
          </div>
          
          <div className="special-offer team-offer">
            <h3>👥 Team Orders</h3>
            <p>Looking to equip your team? We offer volume discounts and flexible licensing options for organizations of all sizes.</p>
          </div>
          
          <div className="contact-info">
            For special pricing inquiries, contact: <a href="mailto:contact@repoprompt.com">contact@repoprompt.com</a>
          </div>
        </div>

      </div>
    </section>
  );
}

export default PricingSection;