import React, { useEffect } from 'react';

function AppcastPage() {
  useEffect(() => {
    // Redirect to the actual XML file
    window.location.href = '/appcast.xml';
  }, []);

  return (
    <div>
      <p>Redirecting to appcast XML...</p>
    </div>
  );
}

export default AppcastPage;