import React from 'react';
import { FiSearch, FiFolder, FiCpu, FiSliders, FiClipboard, FiCode, FiPower, FiLock, FiAlertTriangle } from 'react-icons/fi';
import './Hero.css';

function Hero() {
  return (
    <section id="hero" className="hero">
      <div className="hero-container">
        <div className="hero-logo-container">
          <img src="/images/RepoPromptLogo_NoBG.png" alt="Repo Prompt Logo" className="hero-logo" />
        </div>
        
        {/* "Repo Prompt" as the headline */}
        <h1 className="hero-headline">Repo Prompt</h1>
        
        {/* Refined subtitle split */}
        <p className="hero-subtitle" style={{ marginBottom: '0.5rem' }}>
      Code smarter with AI—no more messy copy-pasting.
        </p>
        <p className="hero-subtitle" style={{ fontSize: '1.3rem', opacity: 0.7, marginBottom: '2rem' }}>
          Let Repo Prompt structure your prompts and apply AI changes for you.
    </p>
        

        
        {/* CTA container */}
        <div className="cta-container">
          <a href="https://d2ruaev2blr0t3.cloudfront.net/RepoPrompt.dmg" className="cta-button" target="_blank" rel="noopener noreferrer">
            Download for Mac
          </a>
          <a href="https://forms.gle/TGx1ZuT3oqng7EuKA" className="cta-button" target="_blank" rel="noopener noreferrer">
            Windows/Linux Waitlist
          </a>
          <a href="https://discord.gg/NtbFDAJPGM" className="cta-button discord-button" target="_blank" rel="noopener noreferrer">
            <img src="/images/icon_clyde_blurple_RGB.svg" alt="Discord Logo" className="discord-logo" />
            Join Discord
          </a>
        </div>

        {/* "Why Repo Prompt?" unchanged */}
        <div className="hero-features-container">
          <h2 className="hero-section-title">Why Repo Prompt?</h2>
          <div className="feature-grid">
            <div className="feature-item">
              <div className="feature-icon"><FiFolder /></div>
              <div className="feature-text">
                <span className="feature-highlight">Zipping your whole repo?</span>
                <p>Too much bloat—send only what’s needed</p>
              </div>
            </div>
            <div className="feature-item">
              <div className="feature-icon"><FiCpu /></div>
              <div className="feature-text">
                <span className="feature-highlight">LLM underperforming?</span>
                <p>Cut the noise for sharper answers</p>
              </div>
            </div>
            <div className="feature-item">
              <div className="feature-icon"><FiCode /></div>
              <div className="feature-text">
                <span className="feature-highlight">Better AI coding?</span>
                <p>Select just the files that matter</p>
              </div>
            </div>
          </div>
        </div>

        {/* "How It Works Today" section */}
        <div id="how-it-works" className="hero-features-container">
          <h2 className="hero-section-title">How It Works Today</h2>
          <div className="feature-grid">
            <div className="feature-item">
              <div className="feature-icon"><FiSearch /></div>
              <div className="feature-text">
                <span className="feature-highlight">Advanced File Selection & Token Estimation</span>
                <p>Precisely select files with advanced filtering and instantly estimate token usage, optimizing your prompts for clarity, efficiency, and cost.</p>
              </div>
            </div>
            <div className="feature-item">
              <div className="feature-icon"><FiFolder /></div>
              <div className="feature-text">
                <span className="feature-highlight">Optimized XML Prompt</span>
                <p>Structured file tree, CodeMap, content, and instructions clearly organized with XML for optimal LLM clarity</p>
              </div>
            </div>
            <div className="feature-item">
              <div className="feature-icon"><FiCode /></div>
              <div className="feature-text">
                <span className="feature-highlight">Structured XML Diffs</span>
                <p>LLM outputs structured XML edits, and Repo Prompt locally converts these into precise diffs for seamless review and merging at any file size</p>
              </div>
            </div>
            <div className="feature-item">
              <div className="feature-icon"><FiCpu /></div>
              <div className="feature-text">
                <span className="feature-highlight">Codemap Extraction</span>
                <p>Locally scans files to extract classes, functions, and references, reducing tokens and preventing hallucinations. Includes auto-detection of referenced types.</p>
              </div>
            </div>
            <div className="feature-item">
              <div className="feature-icon"><FiSliders /></div>
              <div className="feature-text">
                <span className="feature-highlight">Mac-Native Performance</span>
                <p>Optimized for macOS, delivering native performance, responsiveness, and speed—because performance matters.</p>
              </div>
            </div>
            <div className="feature-item">
              <div className="feature-icon"><FiClipboard /></div>
              <div className="feature-text">
                <span className="feature-highlight">Clipboard Integration</span>
                <p>Seamlessly copy structured prompts into any AI chat app—your data stays local, no external API required</p>
              </div>
            </div>
            <div className="feature-item">
              <div className="feature-icon"><FiPower /></div>
              <div className="feature-text">
                <span className="feature-highlight">Works with Any Model</span>
                <p>Supports OpenAI, Anthropic, DeepSeek, Gemini, Azure, OpenRouter, and local models—keeping your workflow private and offline when needed</p>
              </div>
            </div>
            <div className="feature-item">
              <div className="feature-icon"><FiLock /></div>
              <div className="feature-text">
                <span className="feature-highlight">Privacy First</span>
                <p>Supports local models, offline code scanning, and direct clipboard use without APIs. Connect to AI providers without intermediaries.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;