import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function RoadmapRedirect() {
  const navigate = useNavigate();
  
  useEffect(() => {
    // First navigate to home page
    navigate('/');
    
    // After navigation completes, scroll to the roadmap section
    const scrollToRoadmap = () => {
      const roadmapSection = document.getElementById('roadmap');
      if (roadmapSection) {
        roadmapSection.scrollIntoView({ behavior: 'smooth' });
      }
    };
    
    // Small delay to ensure the DOM has loaded
    setTimeout(scrollToRoadmap, 100);
  }, [navigate]);

  return (
    <div className="redirect-container">
      <p>Redirecting to roadmap information...</p>
    </div>
  );
}

export default RoadmapRedirect;