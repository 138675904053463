import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; {new Date().getFullYear()} Repo Prompt. All rights reserved.</p>
        <div className="footer-links">
          <a href="mailto:contact@repoprompt.com" className="footer-link">Contact</a>
          <span className="separator">|</span>
          <a href="/privacy" className="footer-link">Privacy Policy</a>
          <span className="separator">|</span>
          <a href="/terms" className="footer-link">Terms & Conditions</a>
          <span className="separator">|</span>
          <a href="https://polar.sh/repo-prompt/portal/request" className="footer-link" target="_blank" rel="noopener noreferrer">Customer Portal</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;