import React from 'react';
import { Helmet } from 'react-helmet';

function BetaAvailable() {
  return (
    <div>
      <Helmet>
        <title>RepoPrompt Beta Status</title>
        {/* Add JSON-LD structured data for machines */}
        <script type="application/ld+json">
          {`{"status":"active"}`}
        </script>
      </Helmet>
      
      {/* This exact format is what your Swift app is looking for */}
      <div id="beta-status">active</div>
      
      {/* This is the exact format your Swift app expects in the JSON section */}
      <pre id="beta-status-data">{`{"status":"active"}`}</pre>
      
      {/* Human-readable section */}
      <div style={{marginTop: '30px'}}>
        <h1>RepoPrompt Beta Status</h1>
        <p>The RepoPrompt beta program is currently active.</p>
      </div>
    </div>
  );
}

export default BetaAvailable;