import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import './TermsAndConditions.css';

function TermsAndConditions() {
  const navigate = useNavigate();

  return (
    <section className="terms-and-conditions">
      <div className="terms-content">
        <button className="back-button" onClick={() => navigate('/')}>
          <FaArrowLeft /> Back to Home
        </button>
        <h1>Terms and Conditions</h1>

        <div className="terms-section">
          <p>
            <strong>Last Updated:</strong> March 7th, 2025
          </p>
          <p>
            By downloading, installing, or using Repo Prompt ("Software"), you agree to be bound by these Terms and Conditions ("Terms"). If you disagree with any part of these Terms, you must not use the Software.
          </p>
        </div>

        <div className="terms-section">
          <h2>License Grant</h2>
          <p>
            Upon purchase, RepoPrompt grants you a perpetual, non-transferable, non-exclusive license to use the Software on your macOS devices, subject to these Terms. This license includes:
          </p>
          <ul>
            <li>One year of updates from the original purchase date.</li>
            <li>Optionally renewable updates available at $99/year thereafter.</li>
          </ul>
        </div>

        <div className="terms-section">
          <h2>Pricing and Payments</h2>
          <ul>
            <li>Regular Price: $249</li>
            <li>Early-Bird Pricing (first 7 days): $199</li>
            <li>Additional annual updates after the first year: $99/year (optional)</li>
          </ul>
          <p>
            All prices are listed in USD and are subject to change without prior notice.
          </p>
        </div>

        <div className="terms-section">
          <h2>Free Trial</h2>
          <p>
            A full-feature 14-day free trial is available. Upon expiration, you must purchase a license to continue using premium features.
          </p>
        </div>

        <div className="terms-section">
          <h2>Software Features</h2>
          <ul>
            <li>Features may be altered, improved, or removed without prior notice.</li>
            <li>Free tier users will have access to a defined subset of features as listed on the official product page.</li>
          </ul>
        </div>

        <div className="terms-section">
          <h2>Support and Updates</h2>
          <ul>
            <li>Support and updates are available for one year after purchase.</li>
            <li>Continued support and updates require an active update subscription after the initial period.</li>
            <li>RepoPrompt reserves the right to limit support for versions older than one year.</li>
            <li>Support does not cover issues caused by third-party software, outdated systems, or modified environments.</li>
          </ul>
        </div>

        <div className="terms-section">
          <h2>Compatibility and Warranty</h2>
          <p>
            Repo Prompt is provided "AS IS" without warranty of any kind, either express or implied. RepoPrompt does not guarantee compatibility with all macOS versions, third-party software, hardware configurations, or future macOS updates.
          </p>
        </div>

        <div className="terms-section">
          <h2>Liability</h2>
          <p>
            RepoPrompt shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising from your use or inability to use the Software, including data loss or system downtime.
          </p>
        </div>

        <div className="terms-section">
          <h2>Intellectual Property</h2>
          <p>
            Repo Prompt and all related intellectual property rights remain the exclusive property of RepoPrompt. You may not copy, distribute, reverse-engineer, or attempt to derive the source code of the Software.
          </p>
        </div>

        <div className="terms-section">
          <h2>Termination</h2>
          <p>
            RepoPrompt reserves the right to terminate your license immediately without notice if you breach any provision of these Terms.
          </p>
        </div>

        <div className="terms-section">
          <h2>Refund Policy</h2>
          <p>
            All refunds for Repo Prompt are issued directly by us, not Polar. You have the right to request a refund within 14 days of purchase if:
          </p>
          <ul>
            <li>The software does not perform its core functionalities as advertised.</li>
            <li>You have not activated the software license on more than one device.</li>
            <li>You have made reasonable efforts to resolve the issue with our support team.</li>
          </ul>
          <p>
            For refund details or to submit a refund request, please contact us at contact@repoprompt.com.
          </p>
          <p>
            By downloading or otherwise acquiring the Software, you consent to immediate performance and acknowledge that you will lose your right of withdrawal once the download or applicable transmission of the digital content has begun.
          </p>
        </div>

        <div className="terms-section">
          <h2>Data Privacy</h2>
          <p>
            RepoPrompt makes best efforts to minimize data collection. Please see our{' '}
            <a href="/privacy" style={{ color: 'var(--color-accent)', textDecoration: 'underline' }}>
              Privacy Policy
            </a>{' '}
            page for details.
          </p>
        </div>

        <div className="terms-section">
          <h2>Changes to Terms</h2>
          <p>
            We may modify these Terms at any time. Changes become effective upon posting on our website or notifying you via email. Continued use of Repo Prompt after such modifications constitutes acceptance of the updated Terms.
          </p>
        </div>

        <div className="terms-section">
          <h2>Governing Law</h2>
          <p>
            These Terms shall be governed by the laws of Quebec, Canada. Disputes will be settled in the courts located in Montreal, Quebec.
          </p>
        </div>

        <div className="terms-section">
          <h2>Polar Contact Information</h2>
          <p>
            Refunds and payments are managed by:
          </p>
          <p>
            <strong>Polar Software Inc.</strong><br />
            548 Market St PMB 61301<br />
            San Francisco, CA 94104-5401<br />
            USA
          </p>
        </div>

        <div className="terms-section">
          <h2>Contact</h2>
          <p>
            For questions or support requests related directly to Repo Prompt:<br />
            contact@repoprompt.com
          </p>
          <p>
            By using Repo Prompt, you acknowledge that you have read, understood, and agreed to these Terms.
          </p>
        </div>
      </div>
    </section>
  );
}

export default TermsAndConditions;