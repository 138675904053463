import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  const navigate = useNavigate();

  return (
    <section className="privacy-policy">
      <div className="privacy-content">
        <button className="back-button" onClick={() => navigate('/')}>
          <FaArrowLeft /> Back to Home
        </button>
        <h1>Privacy Policy</h1>

        <div className="policy-section">
          <h2>Overview</h2>
          <p>
            Repo Prompt is designed with privacy as a core principle. This privacy policy applies to 
            versions of the application released on or after <strong>March 1, 2025</strong>.  
            We do not collect or store your code, and we minimize data collection to the bare essentials 
            for analytics and service improvements.
          </p>
        </div>

        <div className="policy-section">
          <h2>Data Collection</h2>
          <p>
            We collect minimal, anonymous usage data through PostHog's default setup to track daily active users.
            No personal data, file contents, or identifiable information is collected or stored.
          </p>
        </div>

        <div className="policy-section">
          <h2>Crash Reporting and Performance Monitoring</h2>
          <p>
            Our application uses Sentry.io for crash reporting and performance monitoring purposes only. We have
            configured Sentry to remove IP addresses, disable user session replays and snapshots, and apply
            maximum data scrubbing measures to ensure no personally identifiable information (PII) or sensitive
            data is collected or stored. No custom events are used. The data sent to Sentry is strictly limited to technical details necessary
            for diagnosing crashes and performance issues, such as error type, stack traces, and basic hardware characteristics.
          </p>
        </div>

        <div className="policy-section">
          <h2>Code Privacy</h2>
          <ul>
            <li><strong>No code or files</strong> you open in Repo Prompt are transmitted to or stored on our servers.</li>
            <li><strong>All local processing</strong>: Codemap analysis and other core functionalities run entirely on your machine.</li>
            <li>
              <strong>API integrations are opt-in</strong>: If you choose to use external APIs, your queries are sent  
              directly to your configured provider. We do not intermediate or store these requests.
            </li>
            <li>
              <strong>Clipboard features</strong> operate locally, ensuring no unintended data transmission.
            </li>
          </ul>
          <p>
            While we take privacy seriously, we are not responsible for data handling by third-party API providers.  
            Please review their privacy policies separately.
          </p>
        </div>

        <div className="policy-section">
          <h2>Future Updates</h2>
          <p>
            We are still transitionning out of beta, and until things stabilize, this privacy policy  
            may be updated to reflect any changes in data collection or processing. We remain committed to transparency.
          </p>
        </div>
      </div>
    </section>
  );
}

export default PrivacyPolicy;