import React from 'react';
import './Roadmap.css';

function Roadmap() {
  return (
    <section id="roadmap" className="roadmap">
      <div className="roadmap-content">
        <h2 className="roadmap-title">Roadmap</h2>
        
        <div className="roadmap-items">
          <div className="roadmap-item">
            <h3>Auto File Selection & Prompt Cleanup</h3>
            <p>Automatically pick relevant files and tidy prompts for efficient workflows.</p>
          </div>
          
          <div className="roadmap-item">
            <h3>LLM Refactor (XML Apply)</h3>
            <p>Intelligently reorganize codebase structure using XML-based LLM edits.</p>
          </div>
          
          <div className="roadmap-item">
            <h3>Parallel Feature Workflow</h3>
            <p>Use tabs to seamlessly switch between selected files and prompts.</p>
          </div>
          
          <div className="roadmap-item">
            <h3>Chat Image Support</h3>
            <p>Directly integrate images into your AI conversations.</p>
          </div>
          
          <div className="roadmap-item">
            <h3>PDF & Web Docs Extraction</h3>
            <p>Import structured content from PDFs and web documents.</p>
          </div>
          
          <div className="roadmap-item">
            <h3>MCP Server Integration</h3>
            <p>Use Repo Prompt as an MCP server to pull context from your files into other applications.</p>
          </div>
          
          <div className="roadmap-item">
            <h3>MCP Client Workflows</h3>
            <p>Support for MCP servers in clipboard and chat workflows for seamless context sharing.</p>
          </div>
          
          <div className="roadmap-item">
            <h3>And Much More...</h3>
            <p>We're continuously developing new features to enhance your workflow.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Roadmap;